import { Badge, Box, Heading, HStack, Spacer, VStack, Text, Tabs, Stat, StatLabel, StatNumber, StatHelpText, StatGroup, StatArrow, Select, Skeleton, TabList, Tab, TabPanel, TabPanels, Input, Tag } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { StarIcon, TimeIcon } from "@chakra-ui/icons";

const API_URL = process.env.NODE_ENV === "production" ? "https://api.scarcegames.com/v1" : "http://localhost:4000/v1";

type HistoryEntry = {
  reachedLevel: number;
  elapsedTime: number;
  enemiesKilled: number;
  bossesKilled: number;
  datetime: Date;
}

type StatsEntry = {
  id: string;
  reachedLevel: number;
  runHistory: HistoryEntry[];
}

type StatsResponse = {
  [key: string]: StatsEntry;
}

type StatsEntryCardProps = {
  entry: any;
}

const StatsEntryBadge = (props: any) => {
  const { colorScheme, icon, children } = props;
  return <Badge colorScheme={colorScheme} variant={"outline"} fontSize={"0.8rem"}>
    {icon}
    {children}
  </Badge>;
}

const StatsEntryCard = (props: StatsEntryCardProps) => {
  const { entry } = props;

  return <Box w={"1000px"} minH={"100px"} borderColor={"#202030"} borderWidth={"1px"} borderRadius={"1rem"} p={"1rem"} _hover={
    { borderColor: "#404050" }
  } transition={"all 0.2s ease-in-out"}>
    <Heading as="h2" size="md" color={"white"}>
      {entry.id}
    </Heading>
    <Text fontSize={"0.8rem"} color={"gray.500"}>
      {entry.datetime ?? new Date(entry.datetime).toLocaleString()}
    </Text>
    <Spacer height={"1rem"} />
    <HStack spacing={"0.5rem"} alignItems={"center"}>
      <StatsEntryBadge colorScheme={"green"} icon={<StarIcon />}>
        Reached Level: {entry.reachedLevel}
      </StatsEntryBadge>
      <StatsEntryBadge colorScheme={"purple"} icon={<TimeIcon />}>
        Elapsed Time: {new Date(entry.elapsedTime * 1000).toISOString().substr(11, 8)}
      </StatsEntryBadge>
      <StatsEntryBadge colorScheme={"orange"}>
        Enemies Killed: {entry.enemiesKilled}
      </StatsEntryBadge>
      <StatsEntryBadge colorScheme={"red"}>
        Bosses Killed: {entry.bossesKilled}
      </StatsEntryBadge>
    </HStack>
  </Box>;
}


const Stats = () => {
  const [versions, setVersions] = useState<string[]>([]);
  const [stats, setStats] = useState<StatsResponse | null>(null);
  const [version, setVersion] = useState<string>("");

  const [searchPlayerId, setSearchPlayerId] = useState<string>("");
  const [searchEntries, setSearchEntries] = useState<any[]>([]);

  useEffect(() => {
    fetchVersions();
  }, [])

  useEffect(() => {
    if (versions.length > 0 && version === "") {
      setVersion(versions[0]);
    }
  }, [versions]);

  useEffect(() => {
    fetchStats();
  }, [version]);

  useEffect(() => {
    if (stats) {
      console.log(`stats: ${JSON.stringify(stats)}`);
    }
    setSearchEntries(allStats);
  }, [stats]);

  useEffect(() => {
    console.log(`searchPlayerId: ${searchPlayerId}`);
    // filter by id contains searchPlayerId
    const filteredEntries = searchPlayerId === "" ?
      allStats :
      allStats.filter((entry: any) => entry.id.toLowerCase().includes(searchPlayerId.toLowerCase()));
    setSearchEntries(filteredEntries);
  }, [searchPlayerId]);


  const fetchVersions = async () => {
    const response = await fetch(`${API_URL}/versions`);
    const data = await response.json();
    setVersions(data);
  }

  const fetchStats = async () => {
    setStats(null);
    const response = await fetch(`${API_URL}/${version}/stats`);
    const data = await response.json();
    setStats(data);
  }

  // all stats is a flat array of all entries of all player's stats like:
  // [{id: "player1", reachedLevel: 1, elapsedTime: 1, enemiesKilled: 1, bossesKilled: 1, datetime: "2023-10-01T00:00:00Z"},]
  // sorted by datetime from newest to oldest
  const allStats = stats ? Object.entries(stats).flatMap(([playerId, playerStats]) => {
    return playerStats.runHistory.map((entry: HistoryEntry) => {
      return {
        id: playerId,
        ...entry,
      };
    })
  }).sort((a: any, b: any) => {
    return new Date(b.datetime).getTime() - new Date(a.datetime).getTime();
  }
  ) : [];

  const totalPlayers = Object.keys(stats || {}).length;
  const maxReachedLevel = allStats.length > 0 ? Math.max(...allStats.map((entry: any) => entry.reachedLevel)) : 0;
  const totalEnemiesKilled = allStats.length > 0 ? allStats.reduce((acc: number, entry: any) => acc + entry.enemiesKilled, 0) : 0;
  const runsInLast30Days = allStats.filter((entry: any) => {
    const entryDate = new Date(entry.datetime);
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    return entryDate >= thirtyDaysAgo;
  }).length;
  const runsThisWeek = allStats.filter((entry: any) => {
    const entryDate = new Date(entry.datetime);
    const today = new Date();
    const weekStart = new Date(today.setDate(today.getDate() - today.getDay()));
    return entryDate >= weekStart;
  }).length;
  const runsPreviousWeek = allStats.filter((entry: any) => {
    const entryDate = new Date(entry.datetime);
    const today = new Date();
    const weekStart = new Date(today.setDate(today.getDate() - today.getDay() - 7));
    const weekEnd = new Date(today.setDate(today.getDate() - today.getDay() - 1));
    return entryDate >= weekStart && entryDate < weekEnd;
  }).length;
  const runsThisWeekDelta = runsThisWeek - runsPreviousWeek;

  const playerIds = Object.keys(stats || {});

  // for each entry in stats, create a card with the entry's data
  const statsEntries = allStats.length > 0 ? allStats.map((playerStats: any) => {
    return <StatsEntryCard key={playerStats.datetime} entry={playerStats} />;
  }) : [];

  const searchEntriesFiltered = searchEntries.length > 0 ? searchEntries.map((playerStats: any) => {
    return <StatsEntryCard key={playerStats.datetime} entry={playerStats} />;
  }) : [];

  return <VStack minH={"100vh"} display={"flex"} flexDir={"column"} color={"white"} gap={4} p={"3rem"} bgColor={"#09090B"}>
    <Heading as="h1" size="lg" color={"white"}>
      Dark Omen Stats
    </Heading>
    <Select variant={"outline"} bg={"black"} w={"15rem"} defaultValue={version} onChange={(e) => {
      setVersion(e.target.value);
    }}>
      {versions.map((version) => {
        return <option key={version} value={version} style={{ color: "white", background: "#101014" }}>{version}</option>;
      })}
    </Select>
    {stats ?
      <>
        <StatGroup justifyContent={"space-between"} alignContent={"center"} w={"1000px"}>
          <Stat>
            <StatLabel fontSize={"0.8rem"} color={"gray.500"}>Total Players</StatLabel>
            <StatNumber fontSize={"1.5rem"} color={"white"}>{totalPlayers}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel fontSize={"0.8rem"} color={"gray.500"}>Total Runs</StatLabel>
            <StatNumber fontSize={"1.5rem"} color={"white"}>{allStats.length}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel fontSize={"0.8rem"} color={"gray.500"}>Max Reached Level</StatLabel>
            <StatNumber fontSize={"1.5rem"} color={"white"}>{maxReachedLevel}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel fontSize={"0.8rem"} color={"gray.500"}>Runs in Last 30 Days</StatLabel>
            <StatNumber fontSize={"1.5rem"} color={"white"}>{runsInLast30Days}</StatNumber>
          </Stat>
          {/* Runs this week compared to last week */}
          <Stat>
            <StatLabel fontSize={"0.8rem"} color={"gray.500"}>Runs This Week</StatLabel>
            <StatNumber fontSize={"1.5rem"} color={"white"}>{runsThisWeek}</StatNumber>
            <StatHelpText fontSize={"0.8rem"} color={"gray.500"}>
              {runsThisWeek > runsPreviousWeek ? <StatArrow type="increase" /> : <StatArrow type="decrease" />}{runsThisWeekDelta < 0 ? `-${runsThisWeekDelta}` : `+${runsThisWeekDelta}`} from last week
            </StatHelpText>
          </Stat>
        </StatGroup>
        <Tabs variant={"enclosed"} colorScheme={"purple"} w={"1000px"}>
          <TabList borderColor={"#202030"} borderWidth={"1px"} borderRadius={"0.5rem"}>
            {["Top Runs", "All Stats"].map((tab) => {
              return <Tab key={tab} _selected={{ color: "white", bg: "#101015" }} _focus={{ boxShadow: "none" }} color={"#303040"} borderRadius={"0.5rem"}>{tab}</Tab>;
            })}
          </TabList>
          <TabPanels>
            <TabPanel>
              <VStack>
                {statsEntries.length > 0 ? statsEntries.slice(0, 5) : <Text color={"white"}>No runs found</Text>}
              </VStack>
            </TabPanel>
            <TabPanel>
              <VStack>
                <Input placeholder="Search by Player ID" variant={"outline"} bg={"black"} color={"white"} borderColor={"#202030"} _placeholder={{ color: "gray.500" }} _focus={{ borderColor: "#fff" }} transition={"all 0.1s ease-in-out"}
                  onChange={(e) => {
                    setSearchPlayerId(e.target.value);
                  }} value={searchPlayerId} />
                <Text fontSize={"0.8rem"} color={"gray.500"}>Click on a player ID to filter by that player</Text>
                <HStack>
                  {playerIds.map((playerId) => {
                    return <Tag key={playerId} colorScheme={"white"} variant={"outline"} fontSize={"0.8rem"} onClick={() => {
                      setSearchPlayerId(playerId);
                    }} _hover={{ cursor: "pointer", bg: "#101015" }} borderRadius={"0.5rem"}>{playerId}</Tag>;
                  })}
                </HStack>
                {searchEntriesFiltered.length > 0 ? searchEntriesFiltered : <Text color={"white"}>No runs found</Text>}
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </>
      : <Skeleton isLoaded={false} w={"1000px"} h={"100px"} borderRadius={"1rem"} borderWidth={"1px"} borderColor={"#202030"} startColor="#101020" endColor="#202030" fadeDuration={0} />}
    {/* : <Progress size='sm' colorScheme={"pink"} w={"100%"} isIndeterminate />} */}
  </VStack >
}

export default Stats;
