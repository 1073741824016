import { Box, Code, Heading, Stack } from "@chakra-ui/react";

function Maintenance() {
  return (
    <Box
      minH={"100vh"}
      display={"flex"}
      flexDir={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      bgColor={"gray.100"}
      color={"white"}
    >
      <Stack gap={"1rem"} alignItems={"center"}>
        <Heading as="h1" size="lg">
          Scarce Games
        </Heading>
        <Code colorScheme={"white"} variant={"outline"}>
          This website is currently under construction ...
        </Code>
      </Stack>
    </Box>
  );
}

export default Maintenance;
